<template>
    <FilterWrapper
        v-if="isVisibleComponent"
        :wrapper-class="wrapperClass">
        <b-field>
            <template #label>
                <div class="is-flex">
                    {{ fieldLabel }}
                    <LabelWithAnnotation
                        v-if="tooltip"
                        :tooltip="tooltip">
                    </LabelWithAnnotation>
                </div>
            </template>
            <Component
                v-if="excludedComponentInstance"
                :is="excludedComponentInstance"
                :loading="loading"
                :name="name"
                :included-filters.sync="_includedFilters"
                :excluded-filters.sync="_excludedFilters">
                <template #default="{ value, exclude }">
                    <Component
                        :is="componentInstance"
                        :value="value"
                        v-bind="{ ...$attrs, ...$props, ...defaultProps }"
                        :filter-options="filterOptions"
                        :int-id="hasIntId"
                        @input="exclude ? _excludedFilters = $event : _includedFilters = $event">
                    </Component>
                </template>
            </Component>
            <Component
                v-else
                :is="componentInstance"
                v-bind="{ ...$attrs, ...$props, ...defaultProps }"
                :filter-options="filterOptions"
                :int-id="hasIntId"
                @[event]="$emit(event, $event)">
            </Component>
        </b-field>
    </FilterWrapper>
</template>

<script>
  import { PropsExcludedSelect } from "@core/mixins/select/propsExcludedSelect";
  import { hasPermissions } from "@core/mixins/permissions";
  import { mapGetters, mapState } from "vuex";
  import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";

  export default {
    name: "AccessControlFilter",
    mixins: [PropsExcludedSelect],
    components: { LabelWithAnnotation },

    props: {
      type: {
        type: String,
        required: true,
        validator: value =>
          ["manager", "webmaster", "advertiser", "actualWebmaster", "advertManager", "offer"]
            .includes(value)
      },
      label: {
        type: String,
        default: null
      },
      tooltip: {
        type: String,
        default: null
      },
      event: {
        type: String,
        default: "input"
      },
      wrapperClass: {
        type: String,
        default: "column is-6-real-mobile is-4-tablet is-3-desktop is-one-fifth-fullhd is-2-real-fullhd"
      },
      hasSelectAll: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState({ userPermissions: ({ auth }) => auth.userPermissions }),

      ...mapGetters("verticals", ["isVisibleByAllVerticals"]),

      filterOptions () {
        const defaultFilterOptions = { ...this.$attrs["filter-options"] };
        const data = {
          advertManager: { role: this.advertMangerRoleId || null }
        };
        return { ...data[this.type] || {}, ...defaultFilterOptions };
      },

      advertMangerRoleId () {
        return process.env.VUE_APP_ADVERT_MANAGER_ROLE_ID;
      },

      isVisibleComponent () {
        return this.isVisibleByAllVerticals && hasPermissions(this.componentPermissions, this.userPermissions);
      },

      hasIntId () {
        return ["webmaster", "actualWebmaster"].includes(this.type);
      },

      _includedFilters: {
        get () {
          return this.includedFilters;
        },
        set (value) {
          this.$emit("update:includedFilters", value);
        }
      },

      _excludedFilters: {
        get () {
          return this.excludedFilters;
        },
        set (value) {
          this.$emit("update:excludedFilters", value);
        }
      },

      dataPermission () {
        return {
          offer: ["OFFERS.ALL", "OFFERS.FILTERS"],
          manager: ["ADMIN.LIST", "ADMIN.LIST.FILTER"],
          advertManager: ["ADMIN.LIST", "ADMIN.LIST.FILTER"],
          advertiser: ["ADVERTISERS.LIST.ALL", "ADVERTISERS.LIST.OWN", "ADVERTISERS.LIST.UNBIND", "ADVERTISERS.LIST.FILTER"],
          default: ["WEBMASTERS.LIST.ALL", "WEBMASTERS.LIST.OWN", "WEBMASTERS.LIST.UNBIND", "WEBMASTERS.LIST.FILTER"]
        };
      },

      defaultProps () {
        return {
          manager: { sortOptions: { sort: "isBlocked", order: "ASC" } },
          advertManager: { sortOptions: { sort: "isBlocked", order: "ASC" } }
        }[this.type];
      },

      componentPermissions () {
        const { dataPermission, type } = this;
        return dataPermission[type] ?? dataPermission.default;
      },

      componentInstance () {
        const dataSets = {
          offer: "Offers",
          manager: "Managers",
          webmaster: "Webmasters",
          advertiser: "Advertisers",
          advertManager: "Managers",
          actualWebmaster: "ActualWebmasters"
        };
        return () => import(`@/components/Common/Select/${ dataSets[this.type] }Select`);
      },

      excludedComponentInstance () {
        if (this.name) {
          return () => import("@/components/Common/Select/ExcludeSelect");
        } return null;
      },

      fieldLabel () {
        return this.label ?? this.$t(`common.entity.filters.${ this.type }`);
      }
    }
  };
</script>

<style scoped>
</style>
